#react-select-2-listbox{
  z-index: 999999!important;
  cursor: pointer;
}

.css-13cymwt-control{
  cursor: pointer;
}

.passwordStrength{
  p{
    text-align: left !important;
  }
}