.accordion{
  .accordion__heading{
    border:solid;
    border: #737373;

  }

  .accordion__panel{
    border-bottom:solid;
    border-bottom: #737373;
  }
}
